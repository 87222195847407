.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

:root {
  --shop-color-bg: #ffffff;
  --shop-color-main: #80c040;
  --shop-color-text: #252a2b;	
  --shop-color-title: #333333;
  --shop-color-hover: #80c040; 
  --shop-color-button: #80c040;
  --shop-color-border: #eae4e8;    

  --home-category-bg-1: #faf1ff;
  --home-category-bg-2: #faf4eb;
  --home-category-bg-3: #f4e6e5;
  --home-category-bg-4: #e6f2f4;
  --home-category-bg-5: #fff6f6;
  --home-collection1-bg: #80c040;
  --home-collection1-icon: #ffff00;
  --home-collection1-color-text: #ffffff;
  --home-collection2-bg: #fff6f6;
  --home-collection3-bg: #ccf0dd;
  --home-collection3-title: #dd0303;

  --footer-bg-color-1: #f5f5f5;
  --footer-bg-color-2: #ffffff;
  --footer-bg-color-copyright: #ffffff;
  --footer-color-title: #333333;
  --footer-color-text: #333333;
  --footer-color-hover: #80c040;
} 

@font-face{   
  font-family:'FontAwesome'; 
  src:url('//theme.hstatic.net/200000507471/1000858963/14/fontawesome-webfont.eot?v=337');
  src:url('//theme.hstatic.net/200000507471/1000858963/14/fontawesome-webfont.eot?v=337') format('embedded-opentype'),url('//theme.hstatic.net/200000507471/1000858963/14/fontawesome-webfont.woff2?v=337') format('woff2'),url('//theme.hstatic.net/200000507471/1000858963/14/fontawesome-webfont.woff?v=337') format('woff'),url('//theme.hstatic.net/200000507471/1000858963/14/fontawesome-webfont.ttf?v=337') format('truetype'),url('//theme.hstatic.net/200000507471/1000858963/14/fontawesome-webfont.svg?v=337') format('svg');
  font-weight:normal;font-style:normal;font-display:swap;
}   
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.nav-menu-link.active li.MuiListItem-root{
  background-color: rgba(25, 118, 210, 0.08);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


			
